@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeueMontrealMedium";
  src: url("./assets/fonts/neuemontreal/NeueMontreal-Medium.otf"),
    format("opentype");
}
@font-face {
  font-family: "NeueMontrealRegular";
  src: url("./assets/fonts/neuemontreal/NeueMontreal-Regular.otf"),
    format("opentype");
}

body {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Neue Montreal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  font-family: "NeueMontrealRegular";
}

/* HeroSection.css */
.hero-container {
  width: 100%;
  height: 100%;
}

.box {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  animation: move 20s infinite linear;
}

.box1 {
  top: 10%;
  left: 10%;
  animation-duration: 15s;
}

.box2 {
  top: 85%;
  left: 5%;
  animation-duration: 25s;
}

.box3 {
  top: 50%;
  left: 70%;
  animation-duration: 20s;
}

.box4 {
  top: 0%;
  left: 20%;
  animation-duration: 30s;
}

.box4 {
  top: 10%;
  left: 90%;
  animation-duration: 30s;
}
/* Keyframes for moving boxes */
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(var(--tx1), var(--ty1));
  }
  50% {
    transform: translate(var(--tx2), var(--ty2));
  }
  75% {
    transform: translate(var(--tx3), var(--ty3));
  }
  100% {
    transform: translate(var(--tx4), var(--ty4));
  }
}

/* Custom properties for each box to ensure they stay within boundaries */
.box1 {
  --tx1: 50%;
  --ty1: 10%;
  --tx2: -20%;
  --ty2: 30%;
  --tx3: 30%;
  --ty3: -15%;
  --tx4: -10%;
  --ty4: 20%;
}
.box2 {
  --tx1: 20%;
  --ty1: -30%;
  --tx2: 40%;
  --ty2: 20%;
  --tx3: -15%;
  --ty3: 10%;
  --tx4: 30%;
  --ty4: 40%;
}
.box3 {
  --tx1: -40%;
  --ty1: 30%;
  --tx2: 20%;
  --ty2: -20%;
  --tx3: 40%;
  --ty3: 15%;
  --tx4: -10%;
  --ty4: 10%;
}
.box4 {
  --tx1: 30%;
  --ty1: 40%;
  --tx2: -20%;
  --ty2: -10%;
  --tx3: 20%;
  --ty3: 30%;
  --tx4: -30%;
  --ty4: 20%;
}
.box5 {
  --tx1: -50%;
  --ty1: 20%;
  --tx2: 20%;
  --ty2: -40%;
  --tx3: 30%;
  --ty3: 10%;
  --tx4: -40%;
  --ty4: 30%;
}
.box6 {
  --tx1: 40%;
  --ty1: -20%;
  --tx2: -30%;
  --ty2: 10%;
  --tx3: 20%;
  --ty3: 30%;
  --tx4: -50%;
  --ty4: 10%;
}
.box7 {
  --tx1: 20%;
  --ty1: 30%;
  --tx2: -40%;
  --ty2: -10%;
  --tx3: 30%;
  --ty3: 20%;
  --tx4: 10%;
  --ty4: -30%;
}

@layer utilities {
  .bg-glossy {
    @apply bg-white bg-opacity-70;
  }
  .dark .bg-glossy {
    @apply bg-gray-800 bg-opacity-70;
    background-image: linear-gradient(
      145deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.1)
    );
  }
}

.glossy-background {
  backdrop-filter: blur(10px);
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}
